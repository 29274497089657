<template>
  <div>
    <component :is="FastLoading" />
    <div v-show="!$store.state.fastCarregando" class="fdn-home">
      <component :is="HomeHeader" :pagina-atual="paginaAtual" />
      <component :is="HomeContent" />
      <component :is="HomeFooter" />
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";

export default {
  name: "HomeExterno",
  data: function () {
    return {
      paginaAtual: this.$route.name,
      fastTemplate: settings.fastTemplate,
      fastComponents: settings.fastComponents,
    };
  },
  computed: {
    HomeHeader() {
      return () =>
        this.fastComponents.HomeExternoHeader
          ? import(`@/templates/${this.fastComponents.HomeExternoHeader}`)
          : import(`@/templates/${this.fastTemplate}/HomeExternoHeader.vue`);
    },
    HomeContent() {
      return () =>
        this.fastComponents.HomeExternoContent
          ? import(`@/templates/${this.fastComponents.HomeExternoContent}`)
          : import(`@/templates/${this.fastTemplate}/${this.paginaAtual}.vue`);
    },
    HomeFooter() {
      return () =>
        this.fastComponents.HomeExternoFooter
          ? import(`@/templates/${this.fastComponents.HomeExternoFooter}`)
          : import(`@/templates/${this.fastTemplate}/HomeExternoFooter.vue`);
    },
    FastLoading() {
      return () =>
        import(`@/templates/${this.fastTemplate}/components/Loading.vue`);
    },
  },
  mounted() {
    //console.log(this.fastComponents.HomeExternoContent);
  },
  created() {},
  methods: {},
};
</script>

<style></style>
